<template>
  <el-dialog title="题目类型编辑" class="info-div600" style="padding: 24px;text-align: left">
    <el-form :model="pageForm" :rules="pageFormRules" ref="formRef">
      <el-form-item label="分类名称" prop="name" label-width="80px">
        <el-input v-model="pageForm.name" autocomplete="off" placeholder="请输入"/>
      </el-form-item>
    </el-form>
    <div class="dialog-footer marginTop30px text-right">
      <button class="subBtn" @click="subData">提交</button>
      <button class="qxBtn" @click="closeDiv">取消</button>
    </div>
  </el-dialog>
</template>
<script>
import {editQuestionType, setQuestionType} from "@/api/examination";
export default {
  name: "classificationEdit",
  props: {
    row: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  data(){
    return{
      pageForm:{
        name:'',
      },
      pageFormRules: {
        name: [
          {required: true, message: '请填写分类名称', trigger: 'blur'},
        ]
      },
      content:['']
    }
  },
  watch: {
    'row': {
      handler(val){
        this.pageForm.name = val.name
      },
      deep: true
    }
  },
  mounted() {

  },
  methods:{
    closeDiv(){
      this.$emit('closeDiv');
    },
    subData(){
      this.$refs.formRef.validate((valid, error) => {
        if (valid) {
          const handler = this.row ? editQuestionType : setQuestionType
          if (this.row) {
            this.pageForm.id = this.row.id
          }
          handler(this.pageForm).then(res => {
            if(res.success){
              this.$root.ElMessage({type:'success',message:res.message});
              this.closeDiv();
              this.$emit('getList');
            }else{
              this.$root.ElMessage.error(res.message);
            }
          })
        } else {
          const key = Object.keys(error)[0]
          const msg = error[key][0].message
          this.$root.ElMessage.error(msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.child_Dic{
  .icon_dic_css{font-size: 20px;margin-left: 10px;color: #cccccc}
}
</style>